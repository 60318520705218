import { Box } from '@mui/material';
import React from 'react';
import Blog from './Blog';

const BlogsToShow = ({ blogs }) => {
  return (
    <Box
      sx={{
        display: { xs: 'block', lg: 'grid' },
        gridTemplateColumns: { lg: '2fr 2fr' },
        gap: 2,
      }}
    >
      {blogs.map((blog) => {
        return <Blog key={blog.slug.current} data={blog} />;
      })}
    </Box>
  );
};

export default BlogsToShow;
