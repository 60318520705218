import { Box, Typography } from "@mui/material";
import React from "react";
import styles from "../../common/styling/styles";

const CategoryButton = ({ handleClick, activeCategory, category }) => {
  return (
    <Box
      sx={{
        border: "2px solid #2D9B83",
        p: "8px 15px",
        m: "0px 8px 8px 0px",
        display: "inline-block",
        borderRadius: 1,
        whiteSpace: "nowrap",
        flexShrink: 0,
        cursor: "pointer",
        backgroundColor:
          activeCategory === category ? styles.deepGreen : styles.white,
        "& .MuiTypography-root": {
          color: activeCategory === category ? styles.white : styles.grey,
        },
        ":hover": {
          backgroundColor: styles.deepGreen,
          "& .MuiTypography-root": {
            color: "white",
          },
        },
      }}
      onClick={() => handleClick(category)}
    >
      <Typography
        variant="subtitle2"
        sx={{
          color: styles.grey,
          fontWeight: 600,
        }}
      >
        {category}
      </Typography>
    </Box>
  );
};

export default CategoryButton;
