import { graphql } from 'gatsby';
import React, { useContext, useState } from 'react';

import StandardSEO from '../components/common/seo/StandardSEO';
import BlogPageHead from '../components/PageComps/BlogPageComps/BlogPageHead';
import Blogs from '../components/PageComps/BlogPageComps/Blogs';
import { DataContext } from '../providers/DataProvider';

const BlogPage = ({ data }) => {
  const { allSanityPost } = data;

  const { blogCategories } = useContext(DataContext);

  const filteredBlogs = allSanityPost.nodes.filter((blog) => {
    blogCategories.add(blog.category);

    if (process.env.GATSBY_STAGING_SITE_VAR) {
      return blog;
    }
    return new Date(blog.publishedAt) <= new Date();
  });

  const queriedBlogs = filteredBlogs;

  const [allBlogs, setAllBlogs] = useState(queriedBlogs);

  const [headerBlog, ...blogs] = allBlogs;

  if (!headerBlog || !blogs) {
    return;
  }

  return (
    <>
      <BlogPageHead data={{ headerBlog, blogs, queriedBlogs, setAllBlogs }} />

      <Blogs data={{ blogs, queriedBlogs, setAllBlogs }} />
    </>
  );
};

export default BlogPage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityBlogPage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityBlogPage(language: { eq: $language }) {
      seo {
        title
        description
        keywords
        name
        type
      }
    }
    allSanityPost(
      sort: { publishedAt: DESC }
      filter: { language: { eq: $language } }
    ) {
      nodes {
        title
        slug {
          current
        }
        publishedAt
        author
        excerpt
        mainImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 1800, formats: [WEBP])
          }
          altText
        }
        category
        paragraphs {
          title
          body: _rawBody
          image {
            asset {
              gatsbyImageData(
                placeholder: BLURRED
                width: 1800
                formats: [WEBP]
              )
            }
            altText
          }
        }
      }
    }
  }
`;
