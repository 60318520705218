import React from 'react';

import { Box, Link } from '@mui/material';

import { getLink } from '../../../utils/getLink';
import Body from '../../common/body/Body';
import styles from '../../common/styling/styles';
import BlogImage from './BlogPageHeadComps/BlogImage';
import PageHeadBlogDetails from './BlogPageHeadComps/PageHeadBlogDetails';
import BlogSearchBar from './BlogSearchBar';
import MobileCategorySideOptions from './MobileCategorySideOptions';

const BlogPageHead = ({ data }) => {
  const { headerBlog } = data;

  const { slug, mainImage, ...blogDetails } = headerBlog;

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: styles.lightGrey,
        pt: 10,
      }}
    >
      <Body paddingY={styles.allTwo} backgroundColor="transparent">
        {/* <Box sx={{ display: { xs: "block", md: "none" } }}>
          <BlogSearchBar />
        </Box> */}

        <MobileCategorySideOptions data={data} />

        <Link href={getLink({ key: `blog/${slug.current}` })} underline="none">
          <Box>
            <BlogImage
              altText={mainImage.altText}
              imageData={mainImage.asset.gatsbyImageData}
            />

            <PageHeadBlogDetails data={blogDetails} />
          </Box>
        </Link>
      </Body>
    </Box>
  );
};

export default BlogPageHead;
