import React from 'react';

import { Box } from '@mui/material';

import FanGoPagination from '../../../pagination/FanGoPagination';
import BlogsToShow from '../BlogsToShow';

const AllBlogs = ({ data }) => {
  const { postsToShow, totalPages, totalItems, page, handlePageChange } = data;

  return (
    <Box>
      <BlogsToShow blogs={postsToShow} />

      {totalItems > 7 && (
        <Box sx={{ width: { md: '70%' }, m: 'auto' }}>
          <FanGoPagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default AllBlogs;
