import { Box } from "@mui/material";
import React, { useContext, useState } from "react";
import { DataContext } from "../../../providers/DataProvider";
import CategoryButton from "./CategoryButton";
import { MainText } from "../../common/texts";
import styles from "../../common/styling/styles";

const BlogCategories = ({ data }) => {
  const { blogCategories, activeCategory, setActiveCategory } =
    useContext(DataContext);

  const categoriesArray: Array<string> = Array.from(blogCategories);

  const { queriedBlogs, setAllBlogs } = data;

  const handleClick = (category) => {
    setActiveCategory(category);

    if (category === "All") {
      return setAllBlogs(queriedBlogs);
    }

    const categoryFilteredBlogs = queriedBlogs.filter(
      (blog) => blog.category === category
    );

    setAllBlogs(categoryFilteredBlogs);
  };

  return (
    <Box sx={{ mb: 3 }}>
      <MainText
        textVariant="h3"
        textAlignment={styles.alignStyleLLLL}
        paddingBottom={styles.allTwo}
        value="Explore"
        fontColor={styles.deepGreen}
        fontWeight={400}
      />

      {categoriesArray.map((category, index) => {
        return (
          <CategoryButton
            key={index}
            handleClick={handleClick}
            activeCategory={activeCategory}
            category={category}
          />
        );
      })}
    </Box>
  );
};

export default BlogCategories;
