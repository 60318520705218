import { Box } from '@mui/material';
import React, { useState } from 'react';
import Body from '../../common/body/Body';
import styles from '../../common/styling/styles';
import AllBlogs from './allBlogs/AllBlogs';
import SideOptions from './sideOptions/SideOptions';

const Blogs = ({ data }) => {
  const { blogs } = data;

  const [page, setPage] = useState(1);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const totalItems = blogs.length;

  const itemsPerPage = 6;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIndex = (page - 1) * itemsPerPage;

  const endIndex = startIndex + itemsPerPage;

  const postsToShow = blogs.slice(startIndex, endIndex);

  return (
    <Body paddingY={styles.allThree}>
      <Box
        sx={{
          display: { md: 'grid' },
          gridTemplateColumns: '4fr 2fr',
          gap: 1,
        }}
      >
        <AllBlogs
          data={{ postsToShow, totalPages, totalItems, page, handlePageChange }}
        />

        <SideOptions data={data} />
      </Box>
    </Body>
  );
};

export default Blogs;
