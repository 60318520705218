import { Box } from "@mui/material";
import React from "react";
import { formatDate } from "../../../common/utils";
import BlogTitleExcerpt from "../BlogTitleExcerpt";
import BlogCategoryDate from "../BlogCategoryDate";

const PageHeadBlogDetails = ({ data }) => {
  const { title, excerpt, category, publishedAt } = data;

  const date = formatDate(publishedAt, "DD MMM, YYYY");

  return (
    <Box>
      <Box
        sx={{
          // px: { md: 3 },
          display: "flex",
          flexDirection: "column",
          pt: 5,
          pb: 3,
          height: "100%",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <BlogTitleExcerpt
            title={title}
            excerpt={excerpt}
            headerFontWeight={500}
            excerptLimit={170}
          />
        </Box>

        <Box>
          <BlogCategoryDate category={category} date={date} />
        </Box>
      </Box>
    </Box>
  );
};

export default PageHeadBlogDetails;
