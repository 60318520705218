import { Pagination } from '@mui/material';
import React from 'react';

const FanGoPagination = ({ count, page, onChange }) => {
  return (
    <Pagination
      sx={{
        pt: 10,
        display: 'flex',
        justifyContent: 'center',
      }}
      size="large"
      color="secondary"
      count={count}
      page={page}
      onChange={onChange}
      showFirstButton
      showLastButton
    />
  );
};

export default FanGoPagination;
