import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import React from 'react';

import { Box } from '@mui/material';

const BlogImage = ({
  altText,
  imageData,
  height = '500px',
  borderRadius = '8px',
}) => {
  const image = getImage(imageData);
  const bgImage = convertToBgImage(image);
  return (
    <BackgroundImage
      aria-label={altText}
      {...bgImage}
      style={{
        backgroundSize: 'cover',
        borderRadius: borderRadius,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        height: height,
        position: 'relative',
        overflow: 'hidden',
      }}
    />
  );
};

export default BlogImage;
