import { Box } from "@mui/material";
import React from "react";
import BlogCategories from "./BlogCategories";

const MobileCategorySideOptions = ({ data }) => {
  return (
    <Box
      sx={{
        display: { xs: "block", md: "none" },
      }}
    >
      <BlogCategories data={data} />
    </Box>
  );
};

export default MobileCategorySideOptions;
