import { Box } from "@mui/material";
import React from "react";
import BlogCategories from "../BlogCategories";
import BlogSearchBar from "../BlogSearchBar";

const SideOptions = ({ data }) => {
  return (
    <Box
      sx={{
        pl: { md: 3 },
        mt: "40px",
        display: { xs: "none", md: "block" },
      }}
    >
      <Box sx={{ width: "100%" }}>
        {/* <BlogSearchBar /> */}

        <BlogCategories data={data} />
      </Box>
    </Box>
  );
};

export default SideOptions;
